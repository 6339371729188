<template>
    <v-container 
        fluid
    >
        <v-row
            justify="center"
        >
            <v-col
                sm="2"
                md="1"
                lg="1"
                xl="1"
                class="ma-2 pa-0"
            >
                <div
                    :class="
                        $vuetify.breakpoint.width < 600 ? 
                            $vuetify.breakpoint.width > 400 ? 'px-custom-425' :
                            $vuetify.breakpoint.width < 325 ? 'px-custom-320' : 'px-custom-375' : 'pa-0 ma-0'"   
                >
                    <v-img
                        contain
                        :src="error ? 'x-mark-256.png' : 'exclamation-256.png'"
                    />
                </div>
            </v-col>
        </v-row>
        <v-row
            justify="center"
        >
            <v-col
                xs="8"
                class="pa-0 ma-0"
            >
                <p
                    class="pa-0 ma-0"
                >
                    <b>{{ $t(textLine1) }}</b>
                </p>
            </v-col>
        </v-row>
        <v-row
            justify="center"
        >
            <v-col
                xs="8"
                class="pa-0 ma-0"
            >
                <p
                    class="pa-0 ma-0 text-wrap"
                >
                    <b>{{ $t(textLine2) }}</b>
                </p>
            </v-col>
        </v-row>
        <v-row
            justify="center"
        >
            <v-col
                class="pa-0 ma-0"
            >
                <v-btn
                    class="mt-5 btnWithRoundedCorner btnHeight42px"
                    elevation="1"
                    tile      
                    :to="{name: 'Index'}"
                >
                    {{ $t(buttonText) }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name:'Error',
    props:{
        textLine1: {
            type: String,
            default: 'global-error'
        },
        textLine2: {
            type:String,
            default: 'contact-support'
        },
        error: {
            type:Boolean,
            default: true
        }
    },
    data(){
        return{
            buttonText: this.error ? this.$t('try-again') : this.$t('go-back')
        }
    }
}
</script>

<style scoped>
.container{
    margin-top:60px;
}
.px-custom-320{
    padding-left: 100px !important;
    padding-right: 100px !important;
}
.px-custom-375{
    padding-left: 120px !important;
    padding-right: 120px !important;
}
.px-custom-425{
    padding-left: 150px !important;
    padding-right: 150px !important;
}
</style>
